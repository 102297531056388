import moment from 'moment-timezone';

export const stateLabels = {
    new: 'New',
    assigning: 'Assigning',
    assigned: 'Assigned',
    arriving: 'Arriving',
    arrived: 'Arrived',
    completing: 'Completing',
    completed: 'Completed',
    canceled: 'Canceled',
    deleted: 'Deleted',
    problem: 'Problem', // TODO: Set correct status
};

export function isBlocked(config) {
    if (!config.useBlockedTime) {
        return false;
    }
    var format = 'hh:mm:ss'

    var now = moment.tz(config.timezone);
    var startBlockTime = moment.tz(config.blockedTimeStart, format, config.timezone);
    var endBlockTime = moment.tz(config.blockedTimeEnd, format, config.timezone);

    if (now.isBetween(endBlockTime, startBlockTime)) {
        return false;
    } else {
        return true;
    }
}