import React from 'react';
import { useTranslation } from 'react-i18next';

export function Footer(props) {
    const { t } = useTranslation();

    return (
        <>
            {props.config.loginUrl && (
                <div className={'footer'}>
                    <p className={'legal'}>
                        {props.config.partnerName} [{props.config.partnerAuthor}] - <a href="/" onClick={() => { localStorage.removeItem('basicauth') }}>{t('logOut')}</a>
                    </p>
                </div>
            )}
        </>
    );
}