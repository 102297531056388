import { Button, Phone } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Frame } from "./Frame";
import { IconBlock } from './IconBlock';

export function Block(props) {
    const { t } = useTranslation();
    
    return (
        <div className={'slide'}>
            <Frame>
                <div className={'content'}>
                    <div className={'card'}>
                        <div className={'card__icon'}>
                            <IconBlock />
                        </div>
                        <h1>{t('orderBlockTitle')}</h1>
                        <p className={'narrow'}>{t('orderBlockText')}</p>
                        <p className={'narrow'}>{t('orderBlockExtra')}</p>
                        <Button className={'custom_button'} onPress={() => window.location = 'tel:' + props.config.phone} size={'l'}
                            iconLeft={<Phone />} variant={'special'}>{props.config.phone}</Button>
                    </div>
                </div>
            </Frame>
        </div>
    );
}
