import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import './moment/de';
import de from './de.json';
import en from './en.json';

export const setLanguage = async tag => {
  await i18n.changeLanguage(tag);
  document.documentElement.lang = i18n.language;
  moment.locale(i18n.language);
};

i18n.use(initReactI18next).init({
  resources: { de: { translation: de }, en: { translation: en } },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: { escapeValue: false },
});

export default i18n;
